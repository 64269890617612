<template>
  <div class="companyNotes">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Notes </CBreadcrumbItem>
    </teleport>

    <CCard style="min-height: 575px">
      <CCardBody id="masonryGrid">
        <CAccordion :active-item-key="1">
          <AccordionItem :mobile-label="'Note'" :item-key="1">
            <CRow class="justify-content-between">
              <CCol sm="12" :lg="6">
                <h4 class="mobile-hide-768 title">Note</h4>
                <h5 class="subHead pc-hide-768">
                  Notes are only visible to users from your company
                </h5>
              </CCol>
              <CCol sm="12" md="6" lg="3">
                <CFormSelect :options="filterOptions" @change="filterChange" />
              </CCol>

              <CCol xs="auto" class="mobile-hide-768">
                <CButton
                  color="primary"
                  style="height: fit-content"
                  @click="handleShowDialog"
                >
                  Add Note
                </CButton>
              </CCol>
              <CCol class="d-grid gap-2 col-10 mx-auto pc-hide-768 mt-3">
                <CButton
                  color="primary"
                  class="w-100"
                  @click="handleShowDialog"
                >
                  Add Note
                </CButton>
              </CCol>
              <CCol xs="12" class="mobile-hide-768 mt-3">
                <h5 class="subHead">
                  Notes are only visible to users from your company
                </h5>
              </CCol>
            </CRow>
            <CRow class="mt-4">
              <CCol
                v-if="items.length === 0 && !loading"
                xs="12"
                class="my-5 emptyNotes"
              >
                <CIcon width="80" name="cilNotes" />
                <h3 class="my-3">
                  There are no any notes from your company yet.
                </h3>
                <CButton
                  size="md"
                  color="primary"
                  @click="handleShowDialog"
                >
                  Add Note
                </CButton>
              </CCol>
              <CCol v-else>
                <masonry-wall :items="items" :column-width="320" :gap="10">
                  <template #default="{ item }">
                    <CCard class="border-light">
                      <CCardBody>
                        <CRow>
                          <CCol xs="12">
                            <CRow>
                              <CCol xs="10">
                                <h5 class="cardTitle">{{ item.title }}</h5>
                              </CCol>
                              <CCol xs="2" style="text-align: right">
                                <CIcon
                                  v-if="item.is_favorite"
                                  size="xl"
                                  name="cilStar"
                                  style="color: #303c54"
                                />
                              </CCol>
                              <CCol xs="12">
                                <p class="lastEdit">
                                  Last edit
                                  {{ lastEditOfNote(item.updated_at) }} by
                                  {{ item.editor_name }}
                                </p>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol xs="12">
                            <TextBlock :item="item" />
                          </CCol>
                          <CCol xs="12" class="mt-2">
                            <CButton
                              size="sm"
                              variant="ghost"
                              @click="handleEdit(item)"
                            >
                              <CIcon name="cilPen" />
                            </CButton>
                            <CButton
                              size="sm"
                              variant="ghost"
                              @click="handleDelete(item.id)"
                            >
                              <CIcon name="cilTrash" />
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </template>
                </masonry-wall>
              </CCol>
            </CRow>
          </AccordionItem>
        </CAccordion>
      </CCardBody>
    </CCard>

    <ConfirmDialog ref="confirmDialog" />
    <FormDialog
      v-model:show="showDialog"
      :note="selectedNote"
      @update="fetchData"
    />
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
