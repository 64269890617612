<template>
  <div :class="[seeMore ? 'blockTextMore' : 'blockText']">
    <p class="cardText">
      {{ seeMore ? item.text : text }}
      <CButton
        v-show="item.text.length > size && !seeMore"
        v-c-tooltip="{ content: 'See more', placement: 'top' }"
        variant="ghost"
        class="dotBtn"
        @click="seeMore = !seeMore"
      >
        ...
      </CButton>
    </p>
  </div>
</template>

<script>
export default {
  name: 'TextBlock',
  props: {
    item: {
      type: Object,
      default: { text: '' },
    },
  },
  data() {
    return {
      size: 630,
      seeMore: false,
    }
  },
  computed: {
    text() {
      return this.item.text.length > this.size
        ? this.item.text.slice(0, this.size - 1)
        : this.item.text
    },
  },
}
</script>

<style scoped>
.cardText {
  font-size: 12px;
  line-height: 15.96px;
  font-weight: 400;
  color: #303c54;
}

.blockText {
  overflow: hidden;
  text-overflow: clip;
  max-height: 300px;
}

.blockTextMore {
  overflow: auto;
  max-height: 400px;
}

@media (max-width: 576px) {
  .blockText {
    max-height: 300px;
  }
  .blockTextMore {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .blockText {
    max-height: 400px;
  }
  .blockTextMore {
    max-height: 500px;
  }
}

@media (max-width: 992px) {
  .blockText {
    max-height: 350px;
  }
  .blockTextMore {
    max-height: 420px;
  }
}

.dotBtn {
  padding: 0;
  margin: 0;
  font-weight: bold;
}
</style>
